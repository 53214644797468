import { CircularProgress, Container, FormControl, Grid, InputLabel, MenuItem, Select, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useReducer } from 'react';
import { Header } from '../../components/header';
import { SubCondominium, Immobile, } from '../../models/condominium';
import { Meter } from '../../models/meter';
import { fetchCondominium, fetchCondominiumMeters } from '../../utlis/condominium_repository';
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { fetchConsumptions } from '../../utlis/appRepository';
import { Consumptions } from './consumptions';
import { ConfigContext } from '../../App';
import { HomePageState, reducer } from './homePageViewModel';

export function MeterSelectionForm(props: { state: HomePageState, dispatch: any, withMeter: boolean }) {
    const state = props.state;
    const dispatch = props.dispatch;

    const filteredMeters = useMemo(() => { 
        return state.meters
        .filter((m: Meter) => {
            if(state.meterType.includes("AF") || state.meterType.includes("ACS") )
            return m.attributes.type.includes("AF") || m.attributes.type.includes("ACS");

            if(state.meterType.includes("CALORIE") || state.meterType.includes("FRIGORIE") )
            return m.attributes.type.includes("CALORIE") || m.attributes.type.includes("FRIGORIE");

            return m.attributes.type == "RIPARTITORE"

        })
        .filter((m: Meter) => !state.selectedImmobile || m.attributes.immobileId == state.selectedImmobile.id!) 
        .filter((m: Meter) => !state.selectedSubCondominium || m.attributes.subCondominiumId == state.selectedSubCondominium.id!) 
    }, [state.meterType, state.meters])
    return <>
        <Grid container justify="center" style={{ marginTop: "2rem" }} xs={12}>


            {state.condominiums.length > 1 && <Grid xs={12} item>

                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="condominio">Condominio</InputLabel>

                    <Select
                        key={JSON.stringify(state.selectedCondominium)}
                        labelId="condominio"
                        style={{ width: '100%' }}
                        id="demo-simple-select"
                        value={state?.selectedCondominium?.id}
                        onChange={(e: any, _) => {
                            try {
                                const cond = state.condominiums.filter((c: any) => c.id == e.target.value)[0];
                                dispatch({ type: "setCondominium", value: cond })
//                                dispatch({ type: "setMeter", value: undefined })
//                                dispatch({ type: "setSubCondominium", value: undefined })
//                                dispatch({ type: "setImmobile", value: undefined })
                            } catch (e) { }
                        }}
                    >

                        {state.condominiums.map((sc) => <MenuItem key={sc.id} value={sc.id}>{
                            (() => {

                                return `${sc?.data?.cond_name ?? "--"}`
                            })()

                        }
                        </MenuItem>)
                        }

                    </Select>
                </FormControl>

            </Grid>}


            { state.selectedCondominium && state.selectedCondominium.children.length > 0 && <Grid xs={12} item>

                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="sottocondominio">Sottocondominio</InputLabel>

                    <Select
                        key={JSON.stringify(state.selectedSubCondominium)}
                        labelId="sottocondominio"
                        style={{ width: '100%' }}
                        id="demo-simple-select"
                        value={state?.selectedSubCondominium?.id ?? "tutti"}
                        onChange={(e: any, _) => {
                            if(e.target.value == "tutti") return dispatch({ type: "setSubCondominium", value: undefined })
                            try {
                                const sc = state.selectedCondominium!.children.filter((c: any) => c.id == e.target.value)[0];
                                dispatch({ type: "setSubCondominium", value: sc as SubCondominium })
//                                dispatch({ type: "setImmobile", value: undefined })
//                                dispatch({ type: "setMeter", value: undefined })
                            } catch (e) { }
                        }}
                    >
                        <MenuItem key={"tutti"} value={"tutti"}>Tutti{` (${state.selectedCondominium!.children.length})`}</MenuItem>
                        {state.selectedCondominium!.children.map((sc) => <MenuItem key={sc.id} value={sc.id}>{
                            (() => {
                                const sc2 = (sc as SubCondominium)
                                return `${sc2?.data?.name ?? ""} ${sc2?.data?.address ?? ""} ${sc2?.data?.number ?? ""} ${sc2?.data?.stair ? `scala ${sc2?.data?.stair}` : ""}`
                            })()

                        }
                        </MenuItem>)
                        }

                    </Select>
                </FormControl>

            </Grid>}

            {state.selectedCondominium && state.selectedSubCondominium && <Grid xs={12} item>

                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="immobile">Immobile</InputLabel>

                    <Select
                        style={{ width: '100%' }}
                        key={JSON.stringify(state.selectedImmobile)}
                        labelId="immobile"
                        id="demo-simple-select"
                        value={state?.selectedImmobile?.id ?? "tutti"}
                        onChange={(e: any, _) => {
                            if(e.target.value == "tutti") return dispatch({ type: "setImmobile", value: undefined })
                            try {
                                const sc = state.selectedSubCondominium!.children.filter((c: any) => c.id == e.target.value)[0];
                                dispatch({ type: "setImmobile", value: sc as Immobile })
//                                dispatch({ type: "setMeter", value: undefined })
                            } catch (e) { }
                        }}
                    >
                        <MenuItem key={"tutti"} value={"tutti"}>Tutti{` (${state.selectedSubCondominium!.children.length})`}</MenuItem>
                        {state.selectedSubCondominium!.children.map((sc) => <MenuItem key={sc.id} value={sc.id}>{
                            (() => {
                                const sc2 = (sc as Immobile)
                                return `${sc2?.data?.name ?? "--"} ${sc2?.data?.flatNumber ?? "--"}`
                            })()

                        }
                        </MenuItem>)
                        }

                    </Select>
                </FormControl>

            </Grid>}
            {
                state.selectedImmobile && props.withMeter && <Grid xs={12} item>

                    <FormControl style={{ width: '100%' }}>
                        <InputLabel id="meter">Meter</InputLabel>
                        {state.loadingMeters && <CircularProgress style={{padding: "2rem"}}/>}
                        {! state.loadingMeters &&<Select
                            style={{ width: '100%' }}
                            key={JSON.stringify(state.selectedMeter)}
                            labelId="meter"
                            id="demo-simple-select"
                            value={state?.selectedMeter?.id ?? "tutti"}
                            onChange={(e: any, _) => {
                                if(e.target.value == "tutti") return dispatch({ type: "setMeter", value: undefined })
                                try {
                                    const m = filteredMeters.filter((c: any) => c.id == e.target.value)[0];
                                    dispatch({ type: "setMeter", value: m as Meter })
                                } catch (e) { }
                            }}
                        >
                            <MenuItem key={"tutti"} value={"tutti"}>Tutti{` (${filteredMeters.length})`}</MenuItem>
                            {filteredMeters!.map((sc) => <MenuItem key={sc.id} value={sc.id}>{
                                (() => {
                                    const m = (sc as Meter)
                                    return `${m?.type ?? ""} ${m?.attributes.serialNumber ?? ""}`
                                })()

                            }
                            </MenuItem>)
                            }

                        </Select>}
                    </FormControl>

                </Grid>
            }


            { props.withMeter && <Grid xs={6} item>
                <KeyboardDatePicker
                    disableToolbar
                    style={{ width: '95%' }}
                    variant="inline"
                    format="DD/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="A partire da"
                    value={state.dateFrom}
                    onChange={(date: any) => {
                        if (date) return dispatch({ type: "setDateFrom", value: date as Date });
                    }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>}
            { props.withMeter && <Grid xs={6} item>
                <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    style={{ width: '95%' }}
                    format="DD/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Fino a"
                    value={state.dateTo}
                    onChange={(date: any) => {

                        if (date) return dispatch({ type: "setDateTo", value: date as Date });
                    }}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
            </Grid>}
            { props.withMeter && <Grid xs={12} item>
                <FormControl style={{ width: '100%' }}>
                    <InputLabel id="Scale">Scala</InputLabel>
                        <Select
                            style={{ width: '100%' }}
                            key={JSON.stringify(state.scale)}
                            labelId="Scala"
                            id="demo-simple-select"
                            value={state?.scale}
                            onChange={(e: any, _) => {
                                try {
                                    dispatch({ type: "setScale", value: e.target.value as any })
                                } catch (e) { }
                            }}
                        >
                        <MenuItem value={"weekly"}>Settimanale</MenuItem>
                        <MenuItem value={"daily"}>Giornaliero</MenuItem>
                        <MenuItem value={"monthly"}>Mensile</MenuItem>
                    </Select>
                </FormControl>
            </Grid>}
        </Grid>

    </>
}
