import moment from "moment";
import { and, BooleanFilter, DataSource, EventFilter, MappedFilter } from "../models/ast";
import { Condominium } from "../models/condominium";
import { Attributes, Meter } from "../models/meter";
import { dc } from "./dataMangement";
import { fetchPaginatedCollection } from "./genericRepository";

export async function getCondominiums(): Promise<Condominium[] | string> {
    return []
}

export async function getMeters(): Promise<Meter[] | string> {
    return []
}

export async function fetchConsumptions(from: Date, to: Date, meterIdentifier: string | {condominium_id: number, sub_condominium_id: string | undefined, immobile_id: string | undefined}, meters: Meter[]): Promise<any[] | string> {
    const mids = meters.reduce((acc: any, x) => {
        acc[x.id] = x.attributes.serialNumber
        return acc;
    }, {});
    const meterss = meters.reduce((acc: any, x) => {
        acc[x.attributes.id!] = x.attributes
        return acc;
    }, {});


    const r = await fetchPaginatedCollection<any>(
        `/api/v1/events?q=${encodeURIComponent(JSON.stringify(toEventFilter(
            moment(from).subtract(1, "day").toDate(), to, meterIdentifier, "consumptions"
        )))}`,
        undefined
        , (items) => {
            // Pre Process
            return items.map((i) => {
                return i;
            })
        })(1, 10000);
    if (typeof r != "string") {

        const r2 = r.sublist.map((it: any) => {
            // delete it["condominium"];
            delete it["condominium_id"];
            delete it["deleted_at"];
            delete it["delta"];
            delete it["final_reading_value"];
            delete it["gateway_identifier"];
            delete it["immobile_id"];
            delete it["initial_reading_value"];
            // delete it["internal"];
            //delete it["measure_unit"];
            // delete it["meter_id"];
            delete it["meter_identifier"];
            // delete it["sub_condominium"];
            delete it["sub_condominium_id"];
            delete it["values"];

            
            it["meter"] = dc(meterss[it["meter_id"]]);
            const room = it["meter"]?.meterHeatDivider?.room ?? ''
            it["measure_unit"] = (() => {
                const meterType = it["meter"]?.type ?? ''
                switch(meterType) {
                    case "RIPARTITORE": return "HCA"
                    case "AF": return "m3"
                    case "ACS": return "m3"
                    case "CALORIE": return "kWh"
                    case "FRIGORIE": return "kWh"
                    case "CALORIE / FRIGORIE": return "kWh"
                    default: return "--"
                }
            })()
            
            if (Object.keys(it.initial_reading_values).length > 0 && Object.keys(it.final_reading_values).length > 0) {
                const initialV = it.initial_reading_values[Object.keys(it.initial_reading_values)[0]]
                const finalV = it.final_reading_values[Object.keys(it.final_reading_values)[0]]

                if(Object.keys(it.initial_reading_values).length > 1) {
                    const initialV = it.initial_reading_values[Object.keys(it.initial_reading_values)[0]]
                    const finalV = it.final_reading_values[Object.keys(it.final_reading_values)[0]]
                    it["v1"] = (it.k ?? 1) * ((Math.round((finalV + Number.EPSILON) * 1000) / 1000) - (Math.round((initialV + Number.EPSILON) * 1000) / 1000))
                    const initialV2 = it.initial_reading_values[Object.keys(it.initial_reading_values)[1]]
                    const finalV2 = it.final_reading_values[Object.keys(it.final_reading_values)[1]]
                    it["v2"] = (it.k ?? 1) * ((Math.round((finalV2 + Number.EPSILON) * 1000) / 1000) - (Math.round((initialV2 + Number.EPSILON) * 1000) / 1000))
                }

                it["serial-number"] = mids[it["meter_id"]]
                it["value"] = (it.k ?? 1) * ((Math.round((finalV + Number.EPSILON) * 1000) / 1000) - (Math.round((initialV + Number.EPSILON) * 1000) / 1000));
                it["label"] = `${it["serial-number"] ? `${it["serial-number"]}\n` : ""}${moment(it["time"]).format("DD/MM")}\n${it["value"].toFixed(2)} ${it["measure_unit"] ?? "HCA"} ${it["measure_unit"] == "HCA" ? `(K: ${it["k"]})` : ""}${room ? `\n${room}` : ""}`
                
            }

            return it;
        }).reverse();

        const unique_dates = new Set(r2.filter(it => !it.meta.interpolated).map(it => it.time))
        return r2.filter(it => !it.meta.interpolated || !unique_dates.has(it.time))
    }



    return r;
}

export async function fetchCumulativeConsumptions(from: Date, to: Date, condominium_id: number, sub_condominium_id: string | undefined, immobile_id: string | undefined, type: string, meters: Meter[]): Promise<any[] | string> {
    
    const metersByIdent = meters.reduce((acc: any, x) => {acc[x.attributes.identifier] = x; return acc; } , {})

    const meterss = meters.reduce((acc: any, x) => {
        acc[x.attributes.identifier] = x.attributes
        return acc;
    }, {});



    
    const r = await fetchPaginatedCollection<any>(
        `/api/v1/events?q=${encodeURIComponent(JSON.stringify(toEventFilterImmobile(
            moment(from).utc().add(-1, 'day').toDate(), to, condominium_id, sub_condominium_id, immobile_id, type
        )))}`,
        undefined
        , (items) => {
            // Pre Process
            return items.map((i) => {
                return i;
            })
        })(1, 10000);
    if (typeof r != "string") {

        const r2 = JSON.parse(JSON.stringify(r.sublist)).map((it: any) => {

            // delete it["condominium"];
            delete it["condominium_id"];
            delete it["deleted_at"];
            delete it["delta"];
            delete it["final_reading_value"];
            delete it["gateway_identifier"];
            delete it["immobile_id"];
            delete it["initial_reading_value"];
            // delete it["internal"];
            // delete it["meter_id"];
            // delete it["meter_identifier"];
            // delete it["sub_condominium"];
            delete it["sub_condominium_id"];
            delete it["values"];



            it["meter_id"] = metersByIdent[it["meter_identifier"]]?.attributes?.id
            it["meter"] = dc(meterss[it["meter_identifier"]]);


            it["measure_unit"] = (() => {
                const meterType = it["meter"]?.type ?? ''
                switch(meterType) {
                    case "RIPARTITORE": return "HCA"
                    case "AF": return "m3"
                    case "ACS": return "m3"
                    case "CALORIE": return "wh"
                    case "FRIGORIE": return "wh"
                    case "CALORIE / FRIGORIE": return "wh"
                    default: return "--"
                }
            })()

            const room = it["meter"]?.meterHeatDivider?.room ?? ''
            it["room"] = room;
            it["sub_condominium"] = (it["meter"] as Attributes)?.subCondominium
            it["immobile"] = (it["meter"] as Attributes)?.immobile

        
            if (Object.keys(it.initial_reading_values).length > 0 && Object.keys(it.final_reading_values).length > 0) {
                const initialV = it.initial_reading_values[Object.keys(it.initial_reading_values)[0]]
                const finalV = it.final_reading_values[Object.keys(it.final_reading_values)[0]]
                it["measure_unit"] = it["measure_unit"] ?? "HCA";
                if(Object.keys(it.initial_reading_values).length > 1) {
                    const initialV = it.initial_reading_values[Object.keys(it.initial_reading_values)[0]]
                    const finalV = it.final_reading_values[Object.keys(it.final_reading_values)[0]]
                    it["v1"] = (it.k ?? 1) * ((Math.round((finalV + Number.EPSILON) * 1000) / 1000) - (Math.round((initialV + Number.EPSILON) * 1000) / 1000))
                    const initialV2 = it.initial_reading_values[Object.keys(it.initial_reading_values)[0]]
                    const finalV2 = it.final_reading_values[Object.keys(it.final_reading_values)[0]]
                    it["v2"] = (it.k ?? 1) * ((Math.round((finalV2 + Number.EPSILON) * 1000) / 1000) - (Math.round((initialV2 + Number.EPSILON) * 1000) / 1000))
                }
                it["value"] = (it.k ?? 1) * ((Math.round((finalV + Number.EPSILON) * 1000) / 1000) - (Math.round((initialV + Number.EPSILON) * 1000) / 1000));
                it["label"] = `${it["meter_serial_number"]}\n${it["value"].toFixed(2)} ${it["measure_unit"]}\n${room}`
            } else {
                it["measure_unit"] = it["measure_unit"] ?? "HCA";
                it["value"] = 0;
                it["label"] = `${it["value"].toFixed(2)} ${it["measure_unit"]}`
            }

            
            return it;
        }).reverse();

        r2.sort((c1: any, c2: any) => c2.value - c1.value)
        //const unique_dates = new Set(r2.filter(it => !it.meta.interpolated).map(it => it.time))
        return r2//.filter(it => !it.meta.interpolated || !unique_dates.has(it.time))
    }



    return r;
}


export async function fetchReadings(robe: {to: Date, condominium_id: number, sub_condominium_id: string | undefined, immobile_id: string | undefined}, meters: Meter[]): Promise<any[] | string> {
    const mids = meters.reduce((acc: any, x) => {
        acc[x.id] = x.attributes.serialNumber
        return acc;
    }, {});
    const meterss = meters.reduce((acc: any, x) => {
        acc[x.attributes.serialNumber!] = x.attributes
        return acc;
    }, {});
    const r = await fetchPaginatedCollection<any>(
        `/api/v1/events?q=${encodeURIComponent(JSON.stringify(toEventFilterReadings(
            {dateBefore: robe.to, ...robe}
        )))}`,
        undefined
        , (items) => {
            // Pre Process
            return items.map((i) => {
                return i;
            })
        })(1, 10000);

    if (typeof r != "string") {
        const r2 = r.sublist.map((it: any) => {
            try {
                if (Object.keys(it.values ?? {}).length > 0) it["value"] = it["values"][Object.keys(it["values"])[0]];
                if(it["value"]){
                   it["label"] = `${moment(it["read_time"]).format("DD/MM")}\n${Object.keys(it.values)[0]}: ${it["value"].toFixed(2)}`
                }             
            } catch (e) {console.error(e)}

            // delete it["condominium"];

            
            it["meter"] = meterss[it["serial_number"]]

            it["measure_unit"] = (() => {
                const meterType = it["meter"]?.type ?? ''
                switch(meterType) {
                    case "RIPARTITORE": return "HCA"
                    case "AF": return "m3"
                    case "ACS": return "m3"
                    case "CALORIE": return "kWh"
                    case "FRIGORIE": return "kWh"
                    case "CALORIE / FRIGORIE": return "kWh"
                    default: return "--"
                }
            })()

            it["meter_id"] = it["meter"]?.id;
            
            const room = it["meter"]?.meterHeatDivider?.room ?? ''
            it["room"] = room;
            it["sub_condominium"] = (it["meter"] as Attributes)?.subCondominium
            it["immobile"] = (it["meter"] as Attributes)?.immobile

            if(Object.values(it["values"] ?? {}).length > 1) {
                
                const values = Object.values(it["values"])
                const calorie: any = values[1] 
                const frigorie: any = values[0] 
                it["value"] = `Calorie: ${(Math.round((calorie / 1000 + Number.EPSILON) * 1000) / 1000)} Frigorie: ${(Math.round((frigorie / 1000 + Number.EPSILON) * 1000) / 1000)}`
            } else {
                it["value"] = Object.values(it["values"] ?? {}).map((it: any) => (Math.round((it + Number.EPSILON) * 1000) / 1000).toString()).join(";")
            }

            
            return it;
        }).reverse();
    
        return r2
    }

    return r;
}





function toEventFilter(fromDate: Date, dateBefore: Date, identifiers: string | {condominium_id: number, sub_condominium_id?: string, immobile_id?: string}, source: DataSource, ): EventFilter {

    const fromDateFilter: BooleanFilter = {
        type: "CONDITION", name: "date_greater", value: fromDate.toISOString()
    }

    const dateBeforeFilter: BooleanFilter = {
        type: "CONDITION", name: "date_smaller", value: dateBefore.toISOString()
    }

    const identifier: BooleanFilter = typeof identifiers == "string" ? { type: "CONDITION", name: "meter_identifier", value: identifiers } : (() => {
        const condominiumId: BooleanFilter = { type: "CONDITION", name: "condominium_id", value: identifiers.condominium_id.toString() }
        const subCondominiumId: BooleanFilter = identifiers.sub_condominium_id ? { type: "CONDITION", name: "sub_condominium_id", value: identifiers.sub_condominium_id } : true
        const immobileId: BooleanFilter = identifiers.immobile_id ? { type: "CONDITION", name: "immobile_id", value: identifiers.immobile_id } : true;
        return and(condominiumId, and(subCondominiumId, immobileId))
    })()

    const value: EventFilter = {
        type: "FILTER",
        source: source,
        filter: and(and(fromDateFilter, dateBeforeFilter), identifier)
    }

    return value;

}

function toEventFilterReadings(identifiers: {condominium_id: number, dateBefore: Date, sub_condominium_id?: string, immobile_id?: string}): EventFilter {



    const dateBeforeFilter: BooleanFilter = {
        type: "CONDITION", name: "date_smaller", value: identifiers.dateBefore.toISOString()
    }

    const identifier: BooleanFilter = typeof identifiers == "string" ? { type: "CONDITION", name: "meter_identifier", value: identifiers } : (() => {
        const condominiumId: BooleanFilter = { type: "CONDITION", name: "condominium_id", value: identifiers.condominium_id.toString() }
        const subCondominiumId: BooleanFilter = identifiers.sub_condominium_id ? { type: "CONDITION", name: "sub_condominium_id", value: identifiers.sub_condominium_id } : true
        const immobileId: BooleanFilter = identifiers.immobile_id ? { type: "CONDITION", name: "immobile_id", value: identifiers.immobile_id } : true;
        return and(condominiumId, and(subCondominiumId, immobileId))
    })()

    const value: EventFilter = {
        type: "FILTER",
        source: "cumulative_meter_readings",
        filter: and(dateBeforeFilter, identifier)
    }

    return value;

}



function toEventFilterImmobile(fromDate: Date, dateBefore: Date, condominium_id: number, sub_condominium_id: string | undefined, immobile_id: string | undefined, type: string): MappedFilter {

    const fromDateFilter: BooleanFilter = {
        type: "CONDITION", name: "date_greater", value: fromDate.toISOString()
    }

    const dateBeforeFilter: BooleanFilter = {
        type: "CONDITION", name: "date_smaller", value: dateBefore.toISOString()
    }

    const condominiumId: BooleanFilter = { type: "CONDITION", name: "condominium_id", value: condominium_id.toString() }
    const subCondominiumId: BooleanFilter = sub_condominium_id ? { type: "CONDITION", name: "sub_condominium_id", value: sub_condominium_id } : true
    const immobileId: BooleanFilter = immobile_id ? { type: "CONDITION", name: "immobile_id", value: immobile_id } : true

    const type_filter: BooleanFilter =  true;

    
    const value: EventFilter = {
        type: "FILTER",
        source: "cumulative_consumptions",
        filter: and(and(and(and(and(fromDateFilter, dateBeforeFilter), condominiumId), subCondominiumId), immobileId), type_filter)
    }

    return {
        "type": "MAP_EX",
        "module": "SwarmBackend.DEX.CumulativeConsumptionMapping",
        "query": value
    }

}

export const debounce = (func: any, wait: any) => {
    let timeout: any;
  
    return function executedFunction(...args: any) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
  
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
