import { Condominium, SubCondominium, Immobile } from "../../models/condominium"
import { Meter } from "../../models/meter"

export type HomePageState = {
    selectedTab: number,
    condominiums: Condominium[]
    meters: Meter[]
    selectedCondominium?: Condominium,
    selectedSubCondominium?: SubCondominium,
    selectedImmobile?: Immobile,
    selectedMeter?: Meter,
    error: string,
    dateFrom: Date,
    dateTo: Date,
    scale: "daily" | "weekly" | "monthly",
    consumptions: any[],
    cumulativeConsumptions: any[],
    meterType: string
    readings: any[],
    loadingData: boolean,
    loadingReading: boolean,
    loadingCumulativeConsumptions: boolean,
    loadingMeters: boolean,
    consumptionsSMSortedBy: string,
    consumptionsSortedBy: string,
    consuptionsSortAsc: boolean,
    consuptionsSMSortAsc: boolean,
    readingsSortedBy: string,
    readingSortedAsc: boolean
}

export type HomePageAction =
    | { type: "setCondominium", value: Condominium }
    | { type: "setCondominiums", value: Condominium[] }
    | { type: "setSubCondominium", value: SubCondominium | undefined }
    | { type: "setImmobile", value: Immobile | undefined }
    | { type: "setMeters", value: Meter[] }
    | { type: "setMeter", value: Meter | undefined }
    | { type: "setTab", value: number }
    | { type: "setError", value: string }
    | { type: "setScale", value: "daily" | "weekly" | "monthly" }
    | { type: "setConsumptions", value: any[] }
    | { type: "setReadings", value: any[] }
    | { type: "setDateTo", value: Date }
    | { type: "setDateFrom", value: Date }
    | { type: "setLoadingData", value: boolean }
    | { type: "setLoadingReading", value: boolean }
    | { type: "setLoadingCumulativeConsumptions", value: boolean }
    | { type: "setCumulativeConsumptions", value: any[] }
    | { type: "setconsumptionsMeterType", value: string }
    | { type: "setLoadingMeter", value: boolean }
    | { type: "setconsumptionsSortedBy", value: string }
    | { type: "setconsumptionsSMSortedBy", value: string }
    | { type: "setconsuptionsSortAsc", value: boolean }
    | { type: "setconsuptionsSMSortAsc", value: boolean }
    | { type: "setreadingsSortedBy", value: string }
    | { type: "setreadingSortedAsc", value: boolean }

export function defaultState(): HomePageState {
    return {
        selectedTab: 0,
        condominiums: [],
        meters: [],
        error: "",
        scale: "daily",
        dateFrom: (() => {
            let d = new Date();
            d.setDate(d.getDate() - 7);
            return d;
        })(),
        dateTo: new Date(),
        consumptions: [],
        cumulativeConsumptions: [],
        loadingCumulativeConsumptions: false,
        meterType: "RIPARTITORE",
        readings: [],
        loadingData: false,
        loadingReading: false,
        loadingMeters: false,
        consumptionsSortedBy: "time",
        consumptionsSMSortedBy: "time",
        readingsSortedBy: "read_time",
        readingSortedAsc: true,
        consuptionsSortAsc: true,
        consuptionsSMSortAsc: true

    }
}

export function dynamicSort(property: string, asc: boolean) {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a: any,b: any) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder * (asc ? 1 : -1);
    }
}

export function reducer(state: HomePageState, action: HomePageAction): HomePageState {
    switch (action.type) {
        case "setTab": return { ...state, selectedTab: action.value, selectedMeter: undefined }
        case "setError": return { ...state, error: action.value }
        case "setCondominiums": return { ...state, condominiums: action.value }
        case "setCondominium": return { ...state, selectedCondominium: action.value, selectedSubCondominium: undefined, selectedMeter: undefined, selectedImmobile: undefined }
        case "setSubCondominium": return { ...state, selectedSubCondominium: action.value, selectedMeter: undefined, selectedImmobile: undefined }
        case "setImmobile": return { ...state, selectedImmobile: action.value, selectedMeter: undefined}
        case "setMeters": return { ...state, meters: action.value }
        case "setMeter": return { ...state, selectedMeter: action.value }
        case "setScale": return { ...state, scale: action.value }
        case "setConsumptions": return { ...state, consumptions: action.value }
        case "setReadings": return { ...state, readings: action.value }
        case "setDateTo": return { ...state, dateTo: action.value }
        case "setDateFrom": return { ...state, dateFrom: action.value }
        case "setLoadingData": return { ...state, loadingData: action.value }
        case "setLoadingReading": return { ...state, loadingReading: action.value }
        case "setLoadingCumulativeConsumptions": return { ...state, loadingCumulativeConsumptions: action.value }
        case "setCumulativeConsumptions": return { ...state, cumulativeConsumptions: action.value }
        case "setLoadingMeter": return { ...state, loadingMeters: action.value }
        case "setconsumptionsMeterType": return {
            ...state, meterType
                : action.value
        }

        case "setconsumptionsSortedBy": return { ...state, consumptionsSortedBy: action.value }
        case "setconsumptionsSMSortedBy": return { ...state, consumptionsSMSortedBy: action.value }
        case "setconsuptionsSortAsc": return { ...state, consuptionsSortAsc: action.value }
        case "setconsuptionsSMSortAsc": return { ...state, consuptionsSMSortAsc: action.value }
        case "setreadingsSortedBy": return { ...state, readingsSortedBy: action.value }
        case "setreadingSortedAsc": return { ...state, readingSortedAsc: action.value }

        default: return state;
    }
}
